import React from "react";
// import Typed from 'react-typed';

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[700px] mt-[20px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <h1 className="text-[#00df90] md:text-3xl md:py-5 sm:text-1xl font-bold flex py-8 mt-5">
          Welcome to Canadian Association of Chinese Martial Arts
        </h1>
        <div className="flex justify-center items-center">
          <p className=" sm-text-sm text-xl font-bold">
            A private martial arts school in London Ontario, specializing in Bak
            Mei, Short Fist Boxing & Wu Tai Chi.<br></br> CAACMA is quality, not
            progression oriented. Progression in the art is something expressed
            in skill, not something you wear. Short Fist lays the foundation for
            Bak Mei while quickly furnishing the students with an effective and
            safe fighting style. New students can be novice, if they are not
            afraid of hard work.
            <br></br>
            Classes are small and spots are limited, but we enjoy training
            dedicated individuals. Observing class can be arranged by
            appointment. If you have any questions, please email us{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
