import React from "react";
import logo from "../Assets/images/CAACMAlogo.jpg";
import crane from "../Assets/images/crane.jpg";

export default function Announcement() {
  const openEmailWindow = () => {
    window.open("mailto:caacmainfo@gmail.com");
  };

  return (
    <div className="w-full bg-white py-10 px-4">
      <p className="text-[#00df90] text-2xl font-bold text-center pb-4">
        Announcements and events
      </p>
      <div className="flex flex-col text-center px-20">
        <h1 className="md:text-2xl sm:text-2xl text-xl font-bold py-2">
          Limited spots available !!!
        </h1>
        <p className="py-3">
          We're a tight-knit group with limited space. Right now, we have a few
          coveted spots open, and we're on the hunt for passionate individuals
          who are ready to train, learn, and grow with us.
        </p>
        <p className="pt-3">
          Ready to take the first step on your martial arts journey? Contact us
          to know more.
        </p>
      </div>
      <div class="text-center">
        <button
          className="bg-black w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 text-white"
          onClick={openEmailWindow}
        >
          Contact us
        </button>
      </div>
    </div>
  );
}
