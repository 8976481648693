import React, {useEffect, useState} from "react";
import panda from "../Assets/images/kung-fu-panda.jpg";

export default function Gallery() {
  const images = [
    "https://picsum.photos/200/300?grayscale",
    "https://picsum.photos/200/300?blur=1",
    "https://picsum.photos/200/300?grayscale&blur=2",
    "https://picsum.photos/200/300?grayscale&random=2",
    "https://picsum.photos/200/300?random=3&blur1",
    "https://picsum.photos/200/300?grayscale&random=3",
    "https://picsum.photos/200/300?&random=4",
    "https://picsum.photos/200/300?grayscale&random=6",
  ];
  const videos = [
    "https://picsum.photos/200/300?blur=2",
    "https://picsum.photos/200/300?random=2",
    "https://picsum.photos/200/300?random",
    "https://picsum.photos/200/300?random=3",
  ];

  return (
    <div className="text-white">
      <h1 class="text-center text-green text-4xl"> COMING SOON !!! </h1>

      {/* <h2 class="font-bold text-3xl text-center p-5">Pictures</h2>
      <div className="grid grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Image ${index + 1}`}
              className="w-300 h-300 object-cover rounded-lg shadow-md"
            />
          </div>
        ))}
      </div> */}
      {/* <div className="text-white">
        <h2 class="font-bold text-3xl text-center p-5">Videos</h2>
        <div className="grid grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="w-300 h-300 object-cover rounded-lg shadow-md"
              />
            </div>
          ))}
        </div>
        
      </div> */}
      <div class="p-10"></div>
    </div>
  );
}
