import React from "react";
import mike from "../Assets/images/MikeAndLee.jpg";
import tiger from "../Assets/images/tiger.jpg";
import crane from "..//Assets/images/crane.jpg";
import panda from "../Assets/images/kung-fu-panda.jpg";
export default function About() {
  return (
    <div class="w-screen  bg-white shadow-md overflow-hidden pb-10">
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-md overflow-hidden mt-8">
        <div className="md:flex">
          <div className="md:shrink-0">
            <img className="w-full h-auto md:w-80 mx-auto" src={mike} alt="" />
          </div>
          <div className="p-8 text-medium flex-grow">
            <p className="mt-2 text-slate-500">
              Mike Doucet, (seated at right) grandson of Grandmaster Daniel K.
              Pai and senior closed-door disciple of Lee Chun Pai. Mike is 8th
              generation Cheung Pak-Mei from Lee Chun Pai from Siu Man Cheung
              from N.K. Ng. Lee Chun Pai (seated at left) is 7th generation Pak
              Mei and the first named grandson of the late Grandmaster Daniel K.
              Pai. Lee is frequently flown in from Vancouver to conduct
              invitation-only seminars. It is then that Lee shares his time and
              knowledge during personal one-on-one instruction.
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-md overflow-hidden mt-8">
        <div className="md:flex">
          <div className="md:shrink-0">
            <img className="w-full h-auto md:w-80 mx-auto" src={tiger} alt="" />
          </div>
          <div className="p-8 text-medium flex-grow">
            <p className="mt-2 text-slate-500">
              Max Doucet, Mike's younger son. Been training since he was young
              and develop a talent and feel for the art like his father. His
              modern outlook on fighting is a great asset to the style and
              technical interpretation on how it's applied in sport concept with
              boxing or mma gloves.
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-md overflow-hidden mt-8">
        <div className="md:flex">
          <div className="md:shrink-0">
            <img className="w-full h-auto md:w-80 mx-auto" src={tiger} alt="" />
          </div>
          <div className="p-8 text-medium flex-grow">
            <p className="mt-2 text-slate-500">
              Rich is one of Mike's oldest student who put in a lot of time and
              effort in the art over the years and brings incredible mental and
              physical toughness.
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-md overflow-hidden mt-8">
        <div className="md:flex">
          <div className="md:shrink-0">
            <img className="w-full h-auto md:w-80 mx-auto" src={crane} alt="" />
          </div>
          <div className="p-8 text-medium flex-grow">
            <p className="mt-2 text-slate-500">
              Shawn is around same age as Max and started in kids class back in
              the day and worked his way over the years to become one of Mike's
              few black sashes. A stickler for details and always on the hunt
              for improvement and helping others to improve
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-md overflow-hidden mt-8 ">
        <div className="md:flex">
          <div className="md:shrink-0">
            <img className="w-full h-auto md:w-80 mx-auto" src={panda} alt="" />
          </div>
          <div className="p-8 text-medium flex-grow">
            <p className="mt-2 text-slate-500">
              Mike is another senior student who's been around for more then 10
              years and earn a brown sash in 2021. He started after moving to
              London as hobby to pass time and learn new martial for fun and
              never left.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
