import React, { StrictMode } from "react";
import siuman from "../Assets/images/CheungSiu-Man.jpg";
import oldSchool from "../Assets/images/pic2.jpg";
import oldSchool2 from "../Assets/images/pic3.jpg";
import oldSchool3 from "../Assets/images/pic4.jpg";
import oldSchool4 from "../Assets/images/pic5.jpg";

export default function History() {
  return (
    <div className="text-white">
      <div class="w-full mx-auto">
        <h1 class=" text-[#00df90] text-center font-extrabold text-2xl px-3 mx-5 py-5 mb-10">
          The origin in the nutshell
        </h1>
        <div class="max-[1240px] md:flex text-center p-10">
          <img class=" w-[300px] flex " src={siuman} alt="/"></img>
          <p class=" p md:p-8 text-center md:text-center">
            The basic story as it was told by sifu Cheung Siu-Man. The style was
            named by Cheung Lai Cheun after his sifu’s sifu. What name it had
            before was not known. Probably, it was a Daoist type taught in the
            temple Cheung Lai Cheun was in. Cheung became obsessed with learning
            after he observed a young monk in a restaurant that the Manchus
            didn’t bother. It took some time to get the monk to talk, but he
            finally got the monk drunk. As Cheung Lai Cheun was proficient at
            Loong Ying, they eventually crossed hands. The monk easily defeated
            him and, as they say, he was hooked. After chasing after the monk’s
            teacher, Cheung became a Daoist priest.
          </p>
        </div>
        <h3 class=" text-[#00df90] text-center font-bold p-4">
          Some of the OGs of the time
        </h3>
        <div class="grid grid-cols-4 gap-2 p-10">
          <img
            class="w-200 h-200 object-cover rounded-lg shadow-md"
            src={oldSchool}
            alt="/"
          />
          <img
            class="w-200 h-200 object-cover rounded-lg shadow-md"
            src={oldSchool2}
            alt="/"
          />
          <img
            class="w-200 h-200 object-cover rounded-lg shadow-md"
            src={oldSchool3}
            alt="/"
          />
          <img
            class="w-200 h-200 object-cover rounded-lg shadow-md"
            src={oldSchool4}
            alt="/"
          />
        </div>
        <div class="p-10">
          <h3 class=" text-[#00df90] text-center font-bold p-4">Background</h3>
          <p>
            In the 60’s, we were known as the “Fire dragon”. In the 70’s, the
            name changed to the “Canadian Kung Fu and Karate Studios”. In the
            80’s, the club was know as the “Bok leen Pai Gar” school. In the
            90’s, we were briefly back with the Pailum. Our current name, while
            long, accurately reflects the depths of knowledge available and our
            philosophy. Chinese martial arts in Asia work well as “athletic
            associations”.
          </p>
          <p>
            We firmly believe in seeking knowledge from teachers who have
            studied with the greats! We respect all arts practiced by “real”
            people. Our martial arts are not for sale! “Kung Fu (Gung Fu) is our
            passion…not our living.” If your goal is to learn something “real”,
            that is not watered down or sold to just anyone for a buck, come and
            see us!!
          </p>
          <h3 class=" text-[#00df90] text-center font-bold p-4">Curriculum</h3>
          <p>
            Students follow a general curriculum with the opportunity to
            specialize. Styles in China are suited to different body types. An
            example would be when confronted with a wall, one would go around,
            another would jump over and yet another would go through. This
            translates into light, medium and heavy skills. Students are thought
            all three and it's up to them to use the teaching in order to find
            his own method to conquer the wall.
          </p>
        </div>

        <div class="p-6 mr-2"></div>
      </div>
    </div>
  );
}
