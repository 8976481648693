import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

import logo from "../Assets/images/CAACMAlogo.jpg";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <div>
      <div className=" bg-gradient-to-r from from-indigo-500 text-white flex justify content-between items-center h-30 max-w[1240] mx-auto px-10 py-6">
        {/* <h1 className="text-[#00df90] w-full text-3xl font-bold"> */}
        <Link class="w-full" to="/">
          <img class="w-24 h-24 rounded-full" src={logo} alt="/" />
        </Link>
        {/* </h1> */}

        <ul className="hidden md:flex">
          <li
            id="kungfu"
            className="p-4 whitespace-nowrap target:shadow-lg hover:text-green-500"
          >
            <Link to="/kungfu">Kung Fu </Link>
          </li>
          <li id="gallery" className="p-4  hover:text-green-500">
            <Link to="/gallery ">Gallery </Link>
          </li>

          <li className="p-4  hover:text-green-500">
            <Link to="/history">History</Link>
          </li>
          <li className="p-4  hover:text-green-500">
            <Link to="/about">About</Link>
          </li>
        </ul>
        {/* Login/Sign Up button */}
        <button class="text-white text-sm px-2 py-2 border rounded-full ml-4 hidden">
          Sign Up
        </button>
        <button class="text-white text-sm px-2 py-2 border rounded-full ml-4 hidden">
          Sign In
        </button>
        <div onClick={handleNav} className="block md:hidden">
          {!nav ? <AiOutlineMenu size={20} /> : <AiOutlineClose size={20} />}
        </div>
        <div
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full border-r-gray-900 bg-[#000300]"
              : "fixed left-[-100%] top-0 w-[60%] h-full border-r-gray-900 bg-[#000300] transition-duration: 500"
          }
        >
          <Link class="w-full" to="/">
            <img class="w-24 h-24 rounded-full m-4" src={logo} alt="/" />
          </Link>

          <ul className="p-4 uppercase">
            <li className="p-4 hover:text-green-500">
              <Link to="/kungfu">Kung Fu </Link>
            </li>
            <li className="p-4  hover:text-green-500">
              <Link to="/gallery">Gallery </Link>
            </li>
            <li className="p-4  hover:text-green-500">
              <Link to="/history">History</Link>
            </li>
            <li className="p-4  hover:text-green-500">
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
