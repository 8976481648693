import React from "react";
import tempIMG2 from "../Assets/images/dog.jpg";

export default function Current() {
  const mikeDemo2 = "https://bakmei-gallery.s3.amazonaws.com/slip+demo2.jpg";
  const mikeDemo1 = "https://bakmei-gallery.s3.amazonaws.com/slip+demo1.jpg";

  return (
    //grabbing the image from s3 bucker

    <div class="bg-white py-20 px-2">
      <div className="container mx-auto flex flex-col md:flex-row gap-2">
        {/* Image Column (60% on the left for larger screens) */}
        <div className="flex flex-col justify-top md:w-80% p-0">
          <img
            className="h-auto w-full md:w-full rounded-full mb-4"
            src={mikeDemo1}
            alt=""
          />
          <img
            className="h-auto w-full md:w-full rounded-full mb-4 p-2"
            src={mikeDemo2}
            alt=""
          />
        </div>

        {/* Text Column (40% on the right for larger screens) */}
        <div className="flex flex-col justify-top md:w-80% p-5">
          <h1 className="md-text-4xl sm:text-2xl justify-center text-center text-xl font-bold py-1">
            Elevating traditional Chinese martial arts in the modern world
          </h1>
          <p className="p-3 text-l">
            Efficiency is born from the marriage of tradition and application.
            Traditional martial arts are not just techniques and forms; they are
            the embodiment of effective self-defense strategies. We strive to
            preserve the essence of Bak mei while arming students with knowledge
            and understanding of the art.
          </p>
          <p className="p-3 text-l">
            Our goal is to provide a safe, welcoming, and inclusive environment
            for students to learn and grow. We promote individual growth and
            custom tailor our instruction to each student's needs.
          </p>
        </div>
      </div>
    </div>
  );
}
