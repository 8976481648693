import React from "react";
import Hero from "../Components/Hero";
import Current from "../Components/Current";
import Announcement from "../Components/Announcement";

export default function Home() {
  return (
    <div>
      <div className="row px-4 mb-10">
        <Hero />
      </div>
      <div className="row px-2 my-20">
        <Current />
      </div>
      <div className="row px-2 my-20">
        <Announcement />
      </div>
    </div>
  );
}
