import React from "react";
import yinYang from "../Assets/images/ying-yang.jpg";
import logo from "../Assets/images/CAACMAlogo.jpg";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div class="bg-gradient-to-r from from-indigo-500 max-w-[1240] mx-auto py-6 px-10 text-gray-300">
      <div class="flex justify-between items-center">
        {/* Column 1: Image */}
        <div class="flex items-center">
          <img class="w-24 h-24 rounded-full" src={logo} alt="/" />
        </div>

        {/* Column 2: List */}
        <div class="flex items-center space-x-6">
          <ul class="list-none p-0 m-0 flex space-x-4">
            <li class="py-2 text-sm">
              <Link to="/about">About </Link>
            </li>
            <li class="py-2 text-sm">
              <Link to="/history"> History</Link>
            </li>
            <li class="py-2 text-sm">
              <a
                href="http://www.pakmei.org/?page_id=335"
                target="_blank"
                rel="noopener noreferrer"
              >
                Global PakMei lineage
              </a>
            </li>

            <li class="py-2 text-sm">
              <a href="mailto:caacmainfo@gmail.com">Contact us</a>
            </li>
          </ul>
        </div>

        {/* Column 3: Social Media Icons */}
        <div class="flex space-x-4 ">
          <a
            href="https://www.instagram.com/caacma_london/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={30} className="text-white" />
          </a>
          <a
            href="https://www.facebook.com/groups/161532880985620"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare size={30} className="text-white" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
