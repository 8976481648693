import React from "react";

export default function KungFu() {
  return (
    <div class="text-white">
      <div class="mb-10">
        <h2 class="text-2xl font-bold text-center p-10">
          Short Fist Boxing (Inner Gung Fu)
        </h2>
        <p class="px-10 mx-10">
          Short Fist Boxing was developed as a quicker learning method compared
          to White Brow and as a preliminary step to mastering that particular
          style. This approach is influenced by Western boxing and incorporates
          certain movements from Pak Mei, including a dedicated set of actions.
          It addresses the historical and present issue of inadequate stamina in
          martial arts, especially considering the demanding nature of
          traditional classes lasting over three hours. The forms within Short
          Fist Boxing have a distinct focus on combat and require a nuanced
          grasp of body control, setting them apart from conventional training.
          The term "ging," which lacks a direct English equivalent, necessitates
          a Chinese-speaking partner for proper comprehension. The style
          integrates uncommon breathing techniques, distinctive body movements,
          and spatial considerations, all tailored for close combat scenarios
          and skillful weapon employment. The training regimen blends martial
          arts maneuvers with Chinese medicinal practices and physical
          conditioning. While these medicines require careful handling due to
          potential hazards, they have the capacity, when used appropriately, to
          strengthen one's hands significantly. The training also encompasses
          sensitivity exercises, sandbag drills, and leg conditioning, guiding
          practitioners from controlled sparring to eventually enduring
          full-contact matches. The ultimate objective of this approach is to
          cultivate adept instructors rather than cater solely to beginners,
          acknowledging the intricacies of imparting knowledge to those
          unfamiliar with the art.
        </p>
      </div>
      <div class="mb-6">
        <h2 class="text-2xl font-bold text-center px-3">Bak Mei</h2>
        <p class="px-10 mx-10">
          Bak mei is a "teachers style". It is assumed that someone coming from
          Lam Yui Quai or Lam Sai Wing or any other teacher with their
          impeccable reputations, would have a solid basis in Chinese Martial
          Arts and therefore, would qualify and have the ability to grasp the
          intricate concepts of the art.
        </p>
      </div>
      <h2 className="text-2xl font-bold text-center p-5 m-3">Forms</h2>
      <div className="flex mb-6">
        <div className="w-1/2 mr-4 pl-20">
          <h3 className="text-2xl font-bold text-justify pl-4 py-1 ml-10">
            Weapons
          </h3>
          <ul className="list-disc pl-4 ml-10">
            <li>Plum Blossom spear</li>
            <li>Double ring knives</li>
            <li>Double short swords</li>
            <li>Quando</li>
            <li>Ugly stick</li>
            <li>Loong Ying pole form</li>
            <li>Loong Ying circular dao</li>
          </ul>
        </div>
        <div className="w-1/2">
          <h3 className="text-2xl font-bold text-justify px-3 py-1">Pak Mei</h3>
          <ul className="list-disc pl-4">
            <li>Jik Bo Sek</li>
            <li>Ci Sup Gee</li>
            <li>Ying Jow Ling Q</li>
            <li>Fay Fong Chern Do</li>
            <li>Saam Mune Kune</li>
            <li>Di Jong Quan</li>
            <li>Gau Bou Toi</li>
            <li>Ng Ying Sin Fa</li>
            <li>Do Dun Fo Day</li>
            <li>Di Paa M Hung Chong Lam Quan</li>
            <li>Say Mune Pa</li>
            <li>Kua Sup Bup</li>
            <li>Mor Kui Mon Fu Ja Lum</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
