/** @format */
import React from "react";
// import { withAuthenticator, Button, Heading } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";
import Navbar from "./Components/Navbar";
import { Footer } from "./Components/Footer";
import Home from "./Pages/Home";
import KungFu from "./Pages/KungFu";
import Gallery from "./Pages/Gallery";
import History from "./Pages/History";
import About from "./Pages/About";

import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <div class="w-full">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kungfu" element={<KungFu />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/history" element={<History />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
